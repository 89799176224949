@use "@styles/variables.scss" as variable;

.label {
  position: relative;
}

.textarea {
  resize: none;
  min-height: 120px;
  overflow: hidden;

  position: relative;
  width: 100%;
  padding: 16px;
  background: variable.$color-dark;
  border: 1px solid variable.$input-border-color;
  border-radius: 4px;
  cursor: pointer;

  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b8b8b8;

  &.invalid {
    outline: none !important;
    border: 1px solid variable.$color-red;
  }

  &:focus {
    outline: none !important;

    &::placeholder {
      color: variable.$color-white;
    }
  }
}

.error {
  font-weight: 400;
  font-size: 11px;
  line-height: initial;

  position: absolute;
  top: calc(100% - 4px);
  color: variable.$color-red;
}

.error_icon {
  position: absolute;
  bottom: 20px;
  right: 16px;
}
