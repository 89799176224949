@import "/styles/media.scss";

.table_head {
  display: grid;
  grid-template-columns: 150px minmax(145px, 1fr) minmax(240px, 2fr) minmax(
      150px,
      1fr
    ) minmax(105px, 0.7fr) 110px;
  gap: 10px;
  text-align: start;
  padding: 8px 24px;

  @include media("screen", "<=desktop") {
    display: none;
  }
}
