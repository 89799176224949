@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.container {
  grid-area: reserve;
  display: flex;
  align-items: center;
  height: 100%;
}

.title {
  display: none;

  font-weight: 400;
  color: variable.$color-regular-grey;
  text-transform: uppercase;
  margin-right: 5px;

  @include media("screen", "<=desktop") {
    display: inline;
  }
}
