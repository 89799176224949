@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.swap_button {
  cursor: pointer;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: variable.$primary-new;
  border: 4px solid variable.$color-bg;
  border-radius: 3rem;

  @include media("screen", "<=tablet") {
    border: none;
    width: 56px;
    height: 56px;
  }
}
