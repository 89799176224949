@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.container {
  display: grid;
  grid-template-areas:
    "heading heading"
    "countries countries"
    "cities cities"
    "accept cancel";
  gap: 24px;
  padding: 32px;

  @include media("screen", "<=tablet") {
    padding: 24px;
    grid-template-columns: 1fr 1fr;
  }
}

.heading {
  grid-area: heading;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}

.countries_container {
  grid-area: countries;
}

.cities_container {
  grid-area: cities;
}

.accept_container {
  grid-area: accept;

  @include media("screen", "<=tablet") {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.cancel_container {
  grid-area: cancel;
  display: none;

  @include media("screen", "<=tablet") {
    display: flex;
    justify-content: flex-end;
  }
}


