@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.container {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 100%;

  @include media("screen", "<=desktop") {
    margin-top: 8px;
  }
}

.divisor {
  flex: 0 0 1px;
  height: 18px;
  background: variable.$color-regular-grey;
}

.side_container {
  display: flex;
  gap: 4px;
}

.count {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
