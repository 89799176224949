@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid variable.$border-color-grey;
  background: rgba(variable.$list-border, .4);
  backdrop-filter: blur(5px);

  @include media("screen", "<=tablet") {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 24px;
  }

  &_cash {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid variable.$border-color-grey;
    background: rgba(variable.$list-border, .4);
    backdrop-filter: blur(5px);

    @include media("screen", "<=desktop") {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      padding: 24px;
    }
  }
}

.title {
  font-size: 24px;

  @include media("screen", "<=tablet") {
    font-size: 20px;
  }
}

.button {
  width: max-content;

  @include media("screen", "<=desktop") {
    width: 100%;
  }
}

.buttons_group {
  display: flex;
  gap: 16px;

  @include media("screen", "<=tablet") {
    flex-direction: column;
  }
}
