@use "@styles/variables.scss" as variable;

.sort_button {
  display: flex;
  gap: 5px;
  // cursor: pointer;
}

.title {
  font-weight: 400;
  text-transform: uppercase;
  color: variable.$color-regular-grey;
  white-space: nowrap;
}
