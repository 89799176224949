@import "/styles/media.scss";

.title {
  min-width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
}

.link_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.icon_container {
  width: 26px;
  height: 26px;

  image-rendering: auto;
  filter: blur(0.5px);

  @include media("screen", "<=tablet") {
    width: 20px;
    height: 20px;
  }

  overflow: hidden;
  border-radius: 3rem;
}

.placeholder_container {
  width: 24px;
  height: 24px;

  @include media("screen", "<=tablet") {
    width: 20px;
    height: 20px;
  }
}

.name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
