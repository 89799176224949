@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.icon_text {
  font-size: 12px;
  line-height: 16px;
  color: variable.$color-white;
  max-width: 320px;
}

.icons {
  height: 100%;
  grid-area: features;
  display: flex;
  gap: 10px;
  align-items: center;

  @include media("screen", "<=desktop") {
    display: none;
  }
}
