@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heading {
  display: flex;
  gap: 24px;
  
  @include media("screen", "<=tablet") {
    flex-wrap: wrap;
  }
}

.title {
  flex-grow: 1;
  font-size: 28px;
  font-weight: 600;

  @include media("screen", "<=tablet") {
    font-size: 20px;
    font-weight: 500;
    flex-basis: 100%;
  }
}

.nonmobile {
  @include media("screen", "<=tablet") {
    display: none;
  }
}

.table_wrapper {
  width: 100%;
  border-radius: 8px;
  background: variable.$list-border;

  @include media("screen", "<=desktop") {
    border-radius: 0;
  }

  @include media("screen", "<=tablet") {
    width: 100vw;
    margin: 0 -12px;
  }
}

.loader_wrapper {
  display: flex;
  justify-content: center;
  background: variable.$color-dark;
  padding: 15px;
  margin: 0 auto;
}

.table {
  position: relative;
  overflow: hidden;
}
