@use "@styles/variables.scss" as variable;
@import "@styles/media.scss";

.details {
  width: 100%;
  padding: 32px;
  border: 2px solid variable.$color-dark-grey;
  border-radius: 16px;
  background-color: transparent;
  cursor: pointer;

  .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: variable.$color-white;
    list-style-type: none;

    &_heading {
      width: 80%;
      font-weight: 600;
      font-size: 18px;
    }

    .icon {
      width: 24px;
      height: 24px;
      background: url(../../../../public/svg/icons/add.svg)
        no-repeat center center;
    }
  }

  &[open] {
    .icon {
      background: url(../../../../public/svg/icons/remove.svg)
        no-repeat center center;
    }
  }
}

@include media("<tablet") {
  .details {
    padding: 16px;
  }

  .summary_heading {
    font-size: 16px;
  }
}
