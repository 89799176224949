@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 16px;
  margin: 0 auto;
  gap: 80px;
  max-width: 1280px;
  width: 100%;

  @include media("screen", "<=desktop") {
    padding: 24px 0;
    gap: 32px;
  }
}

.exchange_container {
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  max-width: 1248px;

  @include media("screen", "<=tablet") {
    padding: 0 12px;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.description {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: variable.$color-regular-grey;

  max-width: 790px;
}
