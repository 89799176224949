@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.course {
  grid-area: course;
  color: variable.$color-regular-grey;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: -0.8px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .bold {
    font-weight: 700;
    font-size: 14px;
  }

  .grey {
    color: variable.$color-regular-grey;
    font-size: 14px;
  }

  .active {
    color: variable.$color-white;
    font-size: 14px;
  }

  @include media("screen", "<=desktop") {
    margin-top: 8px;
  }
}

.course_title {
  text-transform: uppercase;

  display: flex;
  gap: 3px;

  & > .span {
    font-size: 14px;
  }
}

.course_minmax {
  font-size: 12px;
  font-weight: 700;
  color: variable.$color-regular-grey;

  display: flex;
  gap: 6px;

  @include media("screen", "<=desktop") {
    max-width: calc(100vw - 32px);
    margin-top: 4px;
  }
}

.minmax_content {
  gap: 4px;
  text-wrap: stable;
  display: flex;
  flex-wrap: wrap;

}

.divisor {
  font-size: 14px;
  margin-bottom: 2px;
}

.reduced {
  width: min-content;
}