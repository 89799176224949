@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1248px;
  width: 100%;

  @include media("screen", "<=desktop") {
    padding: 0 16px;
  }
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @include media("screen", "<=tablet") {
    grid-template-columns: 1fr;
  }
}
