@use "@styles/variables.scss" as variable;

.wrapper {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .title {
      font-size: 16px;
      font-weight: 700;
    }

    .rating_stars {
      width: 78px;
      display: flex;
      justify-content: space-between;
      .rating_stars__item {
        * {
          fill: #26ae60;
        }
      }
    }
  }

  .country {
    display: flex;
    flex-direction: row;
    gap: 8px;

    &__img {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: variable.$primary;
    }

    &__title {
      font-size: 14px;
      font-weight: 700;
      line-height: 12px;
      color: variable.$color-white;
    }
  }

  .divisor {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: variable.$color-white;
  }

  .info {
    display: flex;
    gap: 16px;
    align-items: center;

    .info_description {
      display: flex;
      gap: 8px;

      &__head {
        font-weight: 400;
        font-size: 14px;
      }

      &__p {
        font-weight: 400;
        font-size: 14px;
        color: variable.$color-white;
      }
    }
  }
}
