@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.wrapper {
  max-width: 1248px;
  width: 100%;

  @include media("screen", "<=desktop") {
    padding: 0 16px;
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @include media("screen", "<=tablet") {
    grid-template-columns: 1fr;
  }
}

.item_wrapper {
  padding: 2px;
  border-radius: 16px;
  background: linear-gradient(90deg, rgba(#5e5e5e, 1), rgba(variable.$primary-new, .3));
}

.item {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  background: variable.$color-dark;
}

.icon_container {
  padding: 12px;
  border-radius: 8px;
  background: variable.$list-border;
}

.icon {
  height: 18px;
}

.title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 800;
  color: variable.$primary-new;
}

.description {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}
