@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.wrapper {
  width: 100%;
  min-height: 70px;
  padding: 0 24px;
  display: grid;
  grid-template-areas: "title features course reserve rating reviews";
  grid-template-columns: 150px minmax(145px, 1fr) minmax(240px, 2fr) minmax(
      150px,
      1fr
    ) minmax(105px, 0.7fr) 110px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  @include media("screen", "<=desktop") {
    grid-template-areas:
      "title rating"
      "course course"
      "reserve reserve"
      "reviews reviews";
    grid-template-rows: min-content;
    grid-template-columns: max-content min-content;
    padding: 24px 16px;
    gap: 16px;
  }

  @include media("screen", "<=desktop") {
    gap: 12px;
  }

  p {
    color: variable.$text-dark-grey;
  }
}

.highlighted {
  p {
    color: variable.$color-white !important;
  }
}

.body_item:nth-child(2n-1) {
  display: block;
  background: variable.$color-dark;
}

.features {
  grid-area: features;

  @include media("screen", "<=desktop") {
    display: "none";
  }
}
