@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.step_wrapper {
  padding: 2px;
  border-radius: 16px;
  height: 100%;
  background: linear-gradient(90deg, rgba(#5e5e5e, 1), rgba(variable.$primary-new, .3));
}

.step_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: variable.$color-dark;
}

.step_heading {
  display: flex;
}

.step_content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.step_title {
  font-size: 18px;
  line-height: 25px;;
  font-weight: 600;
}

.step_description {
  font-size: 16px;
  line-height: 22px;;
  font-weight: 400;
}