@use "@styles/variables.scss" as variable;

.label {
  position: relative;

  display: flex;
  align-items: center;
  gap: 8px;
}

.input {
  display: none;
}

.input:checked ~ .checkbox {
  width: 20px;
  height: 20px;
  background: url(../../../../../../public/svg/icons/checkbox_checked.svg);
  cursor: pointer;
}

.checkbox {
  width: 20px;
  height: 20px;
  background: url(../../../../../../public/svg/icons/checkbox.svg);
  cursor: pointer;

  &:hover {
    background: url(../../../../../../public/svg/icons/checkbox_hover.svg);
  }

  &.invalid {
    background: url(../../../../../../public/svg/icons/checkbox_invalid.svg);
  }
}

.error {
  line-height: normal;
  font-weight: 400;
  font-size: 11px;

  color: variable.$color-red;

  @media screen and (max-width: 600px) {
    position: absolute;
    top: 100%;
  }
}
