@use "@styles/variables.scss" as variable;

.select_container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  cursor: pointer;
}

.icon_container {
  height: 32px;
  width: 32px;
  border-radius: 3rem;
  overflow: hidden;

  &.placeholder {
    background-color: variable.$currency-input-bg;
  }
}

.dropdown_container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}