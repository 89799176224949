@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.wrapper {  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "inv inv ads";
  gap: 24px;
  max-width: 1248px;
  width: 100%;

  @include media("screen", "<=desktop") {
    padding: 0 16px;
  }

  @include media("screen", "<=tablet") {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 464px;
    grid-template-areas:
      "inv"
      "ads";
  }
}

.container {
  grid-area: inv;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 56px;
  border-radius: 16px;
  background: variable.$primary-new;
  backdrop-filter: blur(5px);

  @include media("screen", "<=tablet") {
    gap: 24px;
    padding: 24px 16px;
  }
}

.title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  color: variable.$color-bg;

  @include media("screen", "<=tablet") {
    font-size: 20px;
    line-height: 27px;
  }
}

.list_item {
  display: flex;
  gap: 24px;

  @include media("screen", "<=tablet") {
    gap: 16px;
  }
}

.list_counter {
  font-size: 16px;
  font-weight: 700;
  color: variable.$color-bg;

  display: flex;
  flex: 0 0 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: variable.$counter-bg;
}

.list_description {
  line-height: 22px;
  font-weight: 400;
  font-size: 16px;
  color: variable.$color-bg;

  flex: 1 1 auto;
}

.button_container {
  display: flex;
}

.involve_button {
  color: variable.$color-bg;
  cursor: pointer;

  flex-shrink: 1;
  width: auto;
  padding: 16px 32px;
  border-radius: 8px;
  background-color: variable.$color-white;
}
