$color-bg: #151515;
$color-dark: #252525;
$color-dark-grey: #2C2C2C;
$color-dark-grey2: #202020;
$color-white: #FFFFFF;
$color-black: #000000;
$color-blue-dark: #353067;
$color-red: #FF4B55;
$color-regular-grey: #9D9D9D;
$color-dislike: #523032;

$card-bg:#343434;

$text-dark: #A7A7CD;
$text-grey: #E3E3E3;
$text-dark-grey: #5A5A5A;

$infocard-border: #373737;

$tab-hover: #243C2D;
$tab-active: #18271E;
$tab-bg: #35413A;

$table-light-grey: #3C3C3C;
$table-border: #4A4A4A;
$table-border-active: #547E62;
$table-bg: #2D2D2D;
$table-bg-tr: #484848;
$table-active: #2E4134;

$list-border: #353535;

$popular-bg: #242424;

$header-bg: #1F1F1F;

$footer-bg: #0F0F0F;
$footer-border: #363636;

$primary: #26AE60;
$button-hover:  #129A4C;
$button-pressed: #32BC6D;

$primary-new: #C3F533;
$primary-new-dark: #A3CC2C;
$primary-new-light: #D1F567;

$review-dark: #20211C;

$external-site-bg-color: #b7b7b733;

$border-color-grey: #575757;
$input-border-color: #515151;

$color-red: #FF4B55;

$expand-button-bg-color: #31332A;

$why-us-bg-color: #b7b7b7;

$counter-bg: #B4DA45;

$search-bar-bg: #424242;

$currency-bg-active: #BAE04C33;
$option-bg-active: #3F4235;

$currency-input-bg: #636363;
