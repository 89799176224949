@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @include media("screen", "<=tablet") {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto;
  }
}

.side_container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 32px;
  border: 1px solid variable.$border-color-grey;
  border-radius: 16px;
  background: rgba(variable.$list-border, .4);

  @include media("screen", "<=tablet") {
    padding: 24px 16px;
  }
}

.swap_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.side {
  font-size: 12px;
  line-height: 120%;

  &_title {
    font-weight: 500;
    color: variable.$color-white;
  }
  &_description {
    color: variable.$color-regular-grey;
    
    &_wrapper.hidden {
      display: none;
    }

    & > .danger {
      font-weight: 700;
      color: variable.$color-red;
    }
  }
}
