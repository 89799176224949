@use "@styles/variables.scss" as variable;

.wrapper {
  position: relative;
}

.error {
  font-weight: 400;
  font-size: 11px;
  line-height: initial;

  top: 90%;
  position: absolute;
  color: variable.$color-red;
}
