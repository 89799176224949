@use "@styles/variables.scss" as variable;

.wrapper {
  position: relative;
  display: flex;
  gap: 40px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid variable.$color-dark-grey;
  background: variable.$color-bg;
  overflow: hidden;
}

.currency_input {
  font-size: 22px;
  font-weight: 700;
  
  background-color: inherit;
  border: none;
  outline: none;
}

.placeholder {
  display: flex;
  align-items: center;
  color: variable.$color-regular-grey;
  font-size: 16px;
  margin-left: -24px;
}
