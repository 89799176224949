@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.container {
  grid-area: rating;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;

  p {
    text-align: center;
    min-width: auto;
  }
}

.rating {
  color: variable.$color-white !important;
}
