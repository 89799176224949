@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1248px;

  @include media("screen", "<=desktop") {
    padding: 0 16px;
  }

  @include media("screen", "<=tablet") {
    padding: 0;
  }
}

.heading {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;

  @include media("screen", "<=tablet") {
    padding-left: 16px;
  }
}

.grid_container {
  flex-shrink: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @include media("screen", "<=tablet") {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.swap_container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon_container {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  background: variable.$list-border;
}

.icon_swap {
  width: 28px;
  height: 28px;
}

.address {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;

  flex-shrink: 1;
  padding: 11px 16px;
  border-radius: 8px;
  background: variable.$list-border;
}

.smile {
  font-size: 24px;
  line-height: 24px;
}
