@import "/styles/media.scss";

.icon {
  width: 24px;
  height: 24px;

  @include media("screen", "<=tablet") {
    width: 20px;
    height: 20px;
  }

  &_container {
    border-radius: 6rem;
    height: 24px;
    overflow: hidden;

    @include media("screen", "<=tablet") {
      height: 20px;
      width: 20px;
    }
  }
}
