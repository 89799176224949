@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.modal {
  &_wrapper {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(variable.$color-black, .4);
    overflow-y: hidden;

    @include media("screen", "<=tablet") {
      align-items: flex-end;
    }
  }

  &_container {
    position: relative;
    flex: 1 0 100%;
    max-width: 780px;
    min-height: 50px;

    border: 1px solid variable.$color-regular-grey;
    border-radius: 24px;
    background-color: variable.$list-border;
    backdrop-filter: blur(2px);

    @include media("screen", "<=tablet") {
      bottom: 0;
      border: none;
      border-top: 1px solid variable.$color-regular-grey;
      border-radius: 24px 24px 0 0;
    }
  }
}

.slide_wrapper {
  display: none;
  position: absolute;
  left: 50%;
  top: 4px;
  padding: 12px;
  transform: translateX(-50%);

  @include media("screen", "<=tablet") {
    display: block;
  }
}

.slide_container {
  width: 40px;
  height: 3px;
  border-radius: 3rem;
  background-color: rgba(variable.$color-white, .2);
}

.exit_container {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;

  @include media("screen", "<=tablet") {
    display: none;
  }
}
