@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.section_wrapper {
  max-width: 1376px;
  width: 100%;
  padding: 64px;
  border-radius: 16px;
  background: rgba(variable.$why-us-bg-color, .33);
  backdrop-filter: blur(5px);
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    width: 270px;
    height: 270px;

    position: absolute;
    left: -69px;
    bottom: -89px;

    border-radius: 270px;
    border: 27px solid variable.$primary-new;
    filter: blur(24.5px);
  }
  &::before {
    content: '';
    width: 406px;
    height: 406px;

    position: absolute;
    right: -121px;
    top: -243px;

    border-radius: 270px;
    border: 27px solid variable.$primary-new;
    filter: blur(24.5px);
  }

  @include media("screen", "<=tablet") {
    border-radius: 0;
    padding: 32px 16px;
  }
}

.section_container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.heading {
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}

.grid_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 48px 24px;

  @include media("screen", "<=desktop-hd") {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 48px;
  }
}

.grid_item {
  flex-basis: 350px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.item_heading {
  display: flex;
  gap: 12px;
  align-items: center;
}

.title {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;

  display: flex;
  align-items: center;
  gap: 12px;
}

.description {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
