@use "@styles/variables.scss" as variable;
@import "/styles/media.scss";

.checkbox_title,
.checkbox_link {
  margin: 0px;
  font-size: 14px;
  line-height: 19px;
  color: variable.$color-white;
  margin: 0px;
  font-size: 14px;
  line-height: 19px;
  color: variable.$color-white;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.checkbox_link {
  color: variable.$primary-new;
}

.wrapper {
  display: grid;
  grid-template-areas:
    "title title"
    "name email"
    "comment comment"
    "terms terms"
    "policy policy"
    "submit submit";
  gap: 16px;
  padding: 32px;

  border-radius: 8px;

  @include media("screen", "<=tablet") {
    grid-template-areas:
      "title title"
      "name name"
      "email email"
      "comment comment"
      "terms terms"
      "policy policy"
      "submit cancel";
    padding: 24px;
  }
}

.title {
  grid-area: title;

  @include media("screen", "<=tablet") {
    text-align: center;
    margin-top: 8px;
  }
}

.submit_container {
  grid-area: submit;
}

.cancel_container {
  grid-area: cancel;
  display: none;

  @include media("screen", "<=tablet") {
    display: flex;
    justify-content: flex-end;
  }
}
