@use "@styles/variables.scss" as variable;
@import '/styles/media.scss';

.wrapper {
  grid-area: ads;

  border-radius: 16px;
  background: gray;

  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}
